import { useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { signIn, signUp } from '../utils/utils';
import { isValidEmail, isValidPassword } from '../utils/utils';
import PasswordInput from '../organisms/PasswordInput';



export default function SignInSignUpForm({ onClose, action}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [agreement, setAgreement] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {

    event.preventDefault();

    if (action === 'sign-up') {
      signUp({email, password});
      onClose(false);
    }
    if (action === 'sign-in') {
      const responseInfo = await signIn({ email, password });

      if (responseInfo.status === 200) {
        onClose(false);
      } else {
        setErrorMessage('Invalid email or password');
      }
    }
  };


  return (
    <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          component="h3"
          variant="h5"
          fontWeight={700}
        >
          {action === 'sign-in' ? 'Sign In' : 'Sign Up'}
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{
            width: '100%',
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              width: '80%',
            }}
          />
          <PasswordInput
            style={{width: '80%'}}
            password={password}
            handlePassword={(e) => {
              setPassword(e.target.value);
              if (e.target.value === '') {
                setErrorMessage('');
              }
            }}
          />
          {
            !isValidPassword(password) && password.length >=6 ?
              <Typography
                sx={{
                  width: '80%',
                }}
                variant="caption"
                color="error"
                display="block"
              >
                Password must contain at least one digit, one uppercase and one lowercase letter, and at least 9 or more characters
              </Typography>
              : ''
          }
          {
            errorMessage.length > 0 && password.length >= 9 &&
              <Typography
                sx={{
                  width: '80%',
                }}
                variant="caption"
                color="error"
                display="block"
              >
                {errorMessage}
              </Typography>
          }

          {action === 'sign-in' && (
            <FormControlLabel
              sx={{
                display: 'flex',
                justifySelf: 'flex-start'
              }}
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
          )}

          {action === 'sign-up' && (
            <FormControlLabel
              sx={{
                display: 'flex',
                justifySelf: 'flex-start',
                width: '80%',
                fontSize: '5px',
              }}
              control={<
                Checkbox value="remember"
                color="primary"
                onChange={(e) => setAgreement(e.target.checked)}
              />}
              label={
              <Typography
                sx={{
                  fontSize: '12px',
                }}
              >
                <Link
                  href="https://drive.google.com/file/d/1VEmqcq-Lrvp9Z6xPP3BelpE7cF8KFUwy/view"
                  target="_blank"
                >
                  I agree to the Privacy Policy
                </Link>
              </Typography>
              }
            />
          )}

          <Button
            type="click"
            fullWidth
            variant="contained"
            disabled={
              !email || !isValidEmail(email) || !password || password.length < 9 ||
              (action === 'sign-up' && !agreement)
            }
            sx={{
              marginTop: '20px',
              marginBottom: '20px',
              width: '80%',
            }}
            onClick={handleSubmit}
          >
            {action === 'sign-in' ? 'Sign In' : 'Sign Up'}
          </Button>
          <Grid container>
            <Grid
            item
            xs
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            >
              <Link href="/forgot-password" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
  );
}
