import React, { useState } from 'react';
import {
  Container,
  CssBaseline,
  Stack,
  useTheme
} from '@mui/material';
import Intro from '../components/landing/IntroComponent';
import TutorialComponent from '../components/landing/TurorialComponent';
import StrengthComponent from '../components/landing/Strength';
import Header from '../components/landing/Header';
import SignInSignUpDialog from '../components/SignInSignUpDialog';
import About from '../components/About';
import HowItWorks from '../components/landing/HowItWorks';
import WhyChooseUs from '../components/landing/WhyChooseUs';

function Home() {

  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [action, setAction] = useState('sign-in');

  const theme = useTheme();
  return (
    <>
      <CssBaseline />
      <Container
        maxWidth={false}
        disableGutters
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Header setIsSignUpOpen={setIsSignUpOpen} setAction={setAction} source={'landing'} />
        <Stack>
            <Intro setIsSignUpOpen={setIsSignUpOpen} setAction={setAction} />
            <HowItWorks />
            <StrengthComponent />
            <WhyChooseUs setIsSignUpOpen={setIsSignUpOpen} setAction={setAction} />
        </Stack>
        <SignInSignUpDialog isOpen={isSignUpOpen} onClose={setIsSignUpOpen} action={action}/>
      </Container>
    </>
  );
}

export { Home };
