import React from 'react';
import ForgotPassword from './pages/ForgotPassword';
import SnackbarProvider from './providers/SnackbarProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { Home } from './pages/Home';
import { Wishes } from './pages/Wishes';
import WishList from './pages/Wishlist';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query'
import { fetchUser } from './utils/utils';
import { Loader } from './components/loader';
import ResetPassword from './pages/ResetPassword';
import { common } from '@mui/material/colors';
import Posts from './pages/Posts';


function RequireAuth({ children }) {
  const navigateTo = useNavigate();

  const { isPending, isError } = useQuery({
    queryKey: ['user'],
    queryFn: fetchUser,
    onError: () => {
      navigateTo('/')
    },
  });

  if (isPending || isError) {
    return <Loader />
  }
  return children;
}

const queryClient = new QueryClient();

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#601E68',
    },
    background: {
      default: common.white,
      secondary: 'a877af',
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#601E68',
          textDecorationColor: '#601E68',
        },
      },
    },
  },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={defaultTheme}>
    <SnackbarProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route
          path='/new-wish'
          element={<RequireAuth><Wishes /></RequireAuth>}
        />
        <Route path='/wishlist/:uid' element={<WishList />} />
        <Route path='/posts' element={<Posts />} />
      </Routes>
    </Router>
    </SnackbarProvider>
    </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
