import * as React from 'react';
import { Box, useTheme } from '@mui/material';
import { isLoggedIn } from '../../services/wishlist';
import { HomeButton, LogOutButton, SignInButton, SignUpButton } from '../../molecules/buttons';

export default function HeadersButtons({ source, handleClick}) {
  const theme = useTheme();
  const loggedIn = isLoggedIn();
  
  if ((source === 'landing' || source === 'externalWishlist') && loggedIn) {
    return (
      <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '300px',
        [theme.breakpoints.down('sm')]: {
          marginLeft: '50px',
        },
      }}
      >
        <LogOutButton />
        <HomeButton />
      </Box>
    );
  };

  if (source === 'landing' || source === 'externalWishlist' && !loggedIn) {
    return (
      <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '300px',
      }}
    >
      <SignInButton handleClick={handleClick}/>
      <SignUpButton handleClick={handleClick}/>
    </Box>
    );
  };
  if (source === 'internalWishlist' && loggedIn) {
    return (
      <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '300px',
      }}
      >
        <LogOutButton />
      </Box>
    );
  };
}