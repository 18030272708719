import {
  Box,
  Card,
  CardMedia,
  Link,
  Typography,
} from "@mui/material";
import BookingCardButton from "../organisms/BookingCardButton";
import placeholder from '../placeholder.png';

export default function WishPopupContent({ wish, source}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 2,
      }}
    >
      <Card
        sx={{
          borderRadius: '10px',
        }}
      >
        <CardMedia
          component="img"
          height={250}
          width={450}
          sx={{
            objectFit: 'contain',
          }}
          image={
            wish?.attributes?.image.data
              ? `${process.env.REACT_APP_BACKEND_URL_PLAIN}${wish.attributes.image.data.attributes.url}`
              : placeholder
          }
          alt="gift"
        />
      </Card>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 2,
          }}
        >
        <Typography
          variant="h5"
          component="div"
          paddingTop={2}
          sx={{
            wordBreak: 'break-word',
          }}
        >
          {wish?.attributes.link ? (
          <Link target="_blank" href={wish?.attributes.link || ''}>
            {wish?.attributes.name || ''}
          </Link>) : (
          <Typography
            gutterBottom
            variant="h5"
            component="div"
          >
            {wish?.attributes.name || ''}
          </Typography>
          )}
        </Typography>
        {
          wish?.attributes?.price > 0 && (
            <Typography
              gutterBottom
              component="div"
              sx={{
                fontSize: '16px',
                color: theme => theme.palette.text.secondary,
                alignSelf: 'flex-start',
              }}
            >
              {`Price: ${wish?.attributes.price}` || ''}
            </Typography>
          )
        }
        {wish.attributes.comment && (
          <Typography
            sx={{
              wordBreak: 'break-word',
              alignSelf: 'flex-start',
            }}
          >{wish.attributes.comment}</Typography>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 2,
          }}
        >
          {(!wish.attributes.is_booked && source !== 'internalWishlist') &&(
            <BookingCardButton wish={wish} style={
              {
                width: '300px',
              } 
            }/>
          )}
        </Box>
      </Box>
    </Box>
  );
}