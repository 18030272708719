import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Link,
} from '@mui/material';
import placeholder from '../placeholder.png';

export default function CustomCardContent({ wish }) {

  return (
    <Card
      key={wish.id}
      sx={{
        width: 300,
        margin: 2,
        height: '330px',
        position: 'relative',
        borderRadius: '5px',
      }}
    >
      <CardMedia
        component="img"
        height={150}
        width={300}
        sx={{
          objectFit: 'contain',
          marginTop: '10px',
          borderRadius: '10px',
        }}
        image={
          wish?.attributes?.image.data
            ? `${process.env.REACT_APP_BACKEND_URL_PLAIN}${wish.attributes.image.data.attributes.url}`
            : placeholder
        }
        alt="gift"
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: 'calc(100% - 140px)',
        }}
      >
        <CardContent>
          <Box
            sx={{
              overflow: 'hidden'
            }}
          >
            <Typography
              noWrap
              gutterBottom
              variant="h5"
              component="div"
            >
              {wish.attributes.link ? (
              <Link target="_blank" href={wish.attributes.link || ''}>
                {wish.attributes.name.length > 30 ? `${wish.attributes.name.substring(0, 30)}...` : wish.attributes.name || ''}
              </Link>) : (
              <Typography
                gutterBottom
                variant="h5"
                component="div"
              >
                {wish.attributes.name.length > 30 ? `${wish.attributes.name.substring(0, 30)}...` : wish.attributes.name || ''}
              </Typography>
              )}
            </Typography>
            {
              wish?.attributes?.price > 0 && (
                <Typography
                  gutterBottom
                  component="div"
                  sx={{
                    fontSize: 14,
                  }}
                >
                  {`Price: ${wish.attributes.price}` || ''}
                </Typography>
              )
            }
          </Box>
          <Box
            position={'relative'}
            height={'25px'}
            maxHeight={'25px'}
            width={'100%'}
          >
            {wish.attributes.comment ? (
              <Typography
                sx={{
                  wordBreak: 'break-word'
                }}
              >{wish.attributes.comment.length > 30 ? `${wish.attributes.comment.substring(0, 30)}...` : wish.attributes.comment }</Typography>
            ) : (
              <Typography
                sx={{
                  wordBreak: 'break-word',
                  color: 'grey',
                }}
              >
                No description
              </Typography>
            )}
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
}

