import * as React from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import checkBox from '../../img/checkbox.png';
import friends from '../../img/friends.png';

function Steps ({ actions }) {

  const theme = useTheme();
  return actions.map((action, index) => {
    return (
      <Stack
        key={index}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          height: 'auto',
          justifyContent: 'flex-start',
          marginBottom: '10px'
        }}
      >
        <Box
          component="img"
          src={checkBox}
          sx={{
            width: '30px',
            height: '30px',
            alignSelf: 'center',
            marginRight: '10px'
          }}
        />
        <Typography
          variant="h6"
          sx={{
            color: '#3a3232',
            width: '90%',
            display: 'flex',
            lineBreak: 'auto',
            lineHeight: '26px',
            alignSelf: 'center',
            fontSize: '20px',
            [theme.breakpoints.up('md')]: {
              fontSize: '24px',
              width: '80%',
            },
          }}
        >
          {action}
        </Typography>
      </Stack>
    );
  })
}


export default function HowItWorks() {

  const actions = [
    'Add your wishes',
    'Share your wishlist with friends and family',
    'Receive gifts you truly want and cherish'
  ];

  const theme = useTheme();

   return (
    <Stack
      maxWidth={false}
      sx={{
        flexDirection: 'column-reverse',
        maxWidth: 'xl',
        marginBottom: '20px',
        alignSelf: 'center',
        alignContent: 'center',
        flexWrap: 'nowrap',
        height: 'auto',
        alignItems: 'revert',
        marginTop: '20px',
        [theme.breakpoints.up('md')]: {
          justifyContent: 'center',
          flexDirection: 'row',
          justifySelf: 'center'
        },
      }}
    >
      <Box
        component="img"
        src={friends}
        sx={{
          width: '30%',
          height: '30%',
          height: 'auto',
          alignSelf: 'center',
          marginLeft: '100px',
          [theme.breakpoints.up('md')]: {
            width: '20%',
            height: '30%',
            marginLeft: '0px',
          },
        }}
      />
      <Stack
        sx={{
          alignItems: 'center',
          alignSelf: 'center',
          width: '80%',
          height: 'auto',
          justifyContent: 'center',
          [theme.breakpoints.up('md')]: {
            marginLeft: '100px',
            width: '30%',
          },
        }}
      >
        <Steps actions={actions} />
      </Stack>
    </Stack>
  );
}