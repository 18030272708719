import * as React from 'react';
import {
  Box,
  Stack,
  Typography,
  Link,
  useTheme
} from '@mui/material';
import logo from '../../img/logo.png';
import HeadersButtons from './HeadersButtons';

export default function Header({ setIsSignUpOpen, setAction, source }) {

  const handleClick = (action) => {
    setIsSignUpOpen(true);
    setAction(action);
  }

  const theme = useTheme();
  return (
    <Stack
    sx={{
      flexDirection: 'row',
      width: '95%',
      color: theme.palette.primary.light,
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: `0.5px solid ${theme.palette.divider}`,
      [theme.breakpoints.down('sm')]: {
        borderBottom: 'none',
      },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
    >
      <Link
        href="/"
        sx={{
          textDecoration: 'none',
        }}
      >
      <Box
        component={'img'}
        src={logo}
        sx={{
          width: '50px',
          height: '50px',
          marginRight: '10px',
          [theme.breakpoints.down('sm')]: {
            width: '30px',
            height: '30px',
          },
        }}
      />
      </Link>
      <Typography
        sx={{
          fontSize: '25px',
          fontWeight: 700,
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
        }}
      >
        <Link
          href="/"
          sx={{
            color: theme.palette.primary.light,
            textDecoration: 'none',
          }}
        >
          wishful touch
        </Link>
      </Typography>
    </Box>
    <HeadersButtons source={source} handleClick={handleClick}/>
  </Stack>
  )
}