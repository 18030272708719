import { Box, Button, Modal, Typography, List, ListItem } from '@mui/material'

export default function ResetPasswordPopup({ open, handleClose, setEmail }) {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: 400,
        minWidth: 350,
        bgcolor: 'background.paper',
        border: 'none', // Remove the border
        boxShadow: 10,
        borderRadius: '10px', // Make the corners more round
        p: 4,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Check the email to reset your password
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
        Email not in your inbox?.

        <List
          sx={{
                listStyleType: 'disc',
                listStylePosition: 'inside'
              }}
            >
            <ListItem sx={{ display: 'list-item' }}>
              Check your spam or social folders
            </ListItem>
            <ListItem sx={{ display: 'list-item' }}>
              If you still can't find it, contact us at <a href="mailto:wishfultouch.noreply@gmail.com">support email</a>
            </ListItem>
        </List>
        </Typography>

        <Button
          onClick={() => {
            setEmail('');
            handleClose(false);
          }}
          variant="contained"
          sx={{
            mt: 2,
            display: 'flex',
            justifySelf: 'center'
          }}
        >
          Got it!
        </Button>
      </Box>
    </Modal>
  )
}