import * as React from 'react';
import {
  Box,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import giftBoxes from '../../img/giftboxes.png';
import { CreateWishlistButton } from '../../molecules/buttons';


export default function Intro({ setIsSignUpOpen, setAction }) {
  const theme = useTheme();
  const handleClick = (action) => {
    setIsSignUpOpen(true);
    setAction(action);
  }

   return (
    <Stack
      maxWidth={false}
      sx={{
        flexDirection: 'column',
        maxWidth: 'xl',
        width: '100%',
        marginBottom: '20px',
        alignSelf: 'center',
        height: 'auto',
        justifySelf: 'center',
        backgroundColor: '#E9B32B',
        [theme.breakpoints.up('md')]: {
          justifySelf: 'flex-start'
        },
      }}
    >
      <Stack
          sx={{
            flexDirection: 'row',
            height: '80%',
            width: '80%',
            alignItems: 'center',
            alignSelf: 'center',
          }}
        >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 8,
            width: '100%',
            justifyContent: 'space-between',
            marginBottom: '30px',
            alignSelf: 'center',
            alignItems: 'center',
            [theme.breakpoints.up('md')]: {
              fontSize: 40,
              marginBottom: '50px',
              width: '70%',
            },
            [theme.breakpoints.down('md')]: {
              marginTop: 4,
            },
          }}
        >
          <Typography
            variant="h2"
            fontSize={25}
            fontWeight={700}
            color = {theme.palette.primary.main}
            sx={{
              fontFamily: '"Dancing Script", cursive',
              fontWeight: '400',
              lineHeight: '1.7',
              display: 'flex',
              marginBottom: '20px',
              alignSelf: 'stretch',
              fontSize: '30px',
              [theme.breakpoints.up('md')]: {
                width: '90%',
                fontSize: '60px',
              },
            }}
            >
            Create the Perfect Wishlist for Meaningful Gifting
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifySelf: 'center',
              alignItems: 'flex-start',
              [theme.breakpoints.up('md')]: {
                width: '100%',
                alignSelf: 'center',
              },
            }}
          >
          </Box>
        </Box>
        <Box
          component={'img'}
          sx={{
            display: 'flex',
            alignSelf: 'flex-end',
            objectFit: 'cover',
            alignSelf: 'center',
            width: '20%',
            paddingTop: '30px',
            [theme.breakpoints.up('md')]: {
              height: '25%',
              width: '25%',
            },
            [theme.breakpoints.down('sm')]: {
              width: '50%',
            },
          }}
          src={giftBoxes}
        />
      </Stack>
      <CreateWishlistButton handleClick={() => handleClick('sign-up')} />
    </Stack>

  );
}