import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
} from '@mui/material';
import { useEditGift } from '../services/wishlist';
import { useSnackbar } from '../providers/SnackbarProvider';


export default function WishForm({ onSubmit, wish, onClose, action }) {
  
  const { mutate: editGift } = useEditGift();
  const { openSnackbar } = useSnackbar();
  const [fileUrl, setFileUrl] = useState('');
  const [text, setText] = useState(wish ? wish.name : '');
  const [link, setLink] = useState(wish && wish?.link ? wish.link : '');
  const [comment, setComment] = useState(wish && wish?.comment ? wish.comment : '');
  const [file, setFile] = useState(wish && wish?.image ? wish.image : '');
  const [price, setPrice] = useState(wish && wish?.price ? wish.price : '');
  const [isMonetaryGift, setIsMonetaryGift] = useState(wish && wish?.isMonetaryGift ? true : false);

  const handleSubmit = event => {

    event.preventDefault();

    if (action === 'edit' && wish.id) {
      editGift({wishId: wish.id, wish: {
        name: text,
        link: link,
        image: file,
        comment,
        price,
        isMonetaryGift
      }}, {
        onSuccess() {
          openSnackbar({ message: 'You successfully change the wish', variant: 'success' });
        },
        onError() {
          openSnackbar({ message: 'An error occurred while changing this gift', variant: 'error' });
        },
        onSettled() {
          onClose();
        },
      });
    }
    if (action === 'add') {
      onSubmit({
        name: text,
        link: link,
        image: file,
        comment,
        price,
        isMonetaryGift
      });
    }
  };

  function handleChange(e) {

    let url = URL.createObjectURL(e.target.files[0]);
    setFile(e.target.files[0]);
    setFileUrl(url);
  }

  return (
    <Container component="main" sx={{ maxHeight: "600px" }}>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 'auto',
        }}
        onSubmit={handleSubmit}
      >
        <TextField
          sx={{
            marginBottom: 2,
          }}
          label="Product name"
          required
          fullWidth
          value={text}
          onChange={(event) => setText(event.target.value)}
        />
        <TextField
          sx={{
            marginBottom: 2,
          }}
          label="Link or product URL"
          fullWidth
          value={link}
          onChange={(event) => setLink(event.target.value)}
        />
        <FormControlLabel
          sx={{
            display: 'flex',
            justifySelf: 'flex-start',
            marginBottom: 2,
          }}
          control={<
            Checkbox value="remember"
            color="primary"
            checked={isMonetaryGift}
            onChange={(e) => setIsMonetaryGift(e.target.checked)}
          />}
          label={
          <Typography
            sx={{
              fontSize: '12px',
            }}
          >
            Check this box if the provided link is a monetary gift 
          </Typography>
          }
        />
        <TextField
          type='number'
          fullWidth
          label="Price"
          value={price}
          onChange={(event) => setPrice(event.target.value)}
          sx={{
            marginBottom: 2,
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
              display: "none",
            },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
          }}
        />
        <TextField
          type='file'
          fullWidth
          onChange={handleChange}
          sx={{
            marginBottom: 2,
            overflowY: 'scroll',
          }}
        />
        <TextField
          sx={{
            marginBottom: 2,
          }}
          label="Put your comment here"
          fullWidth
          multiline
          rows={6}
          type='text'
          inputProps={{ maxLength: 200 }}
          value={comment}
          onChange={(event) => setComment(event.target.value)}
        />
        {(wish?.image?.length || (fileUrl?.length) > 0) && (
          <Card sx={{
            display: 'flex',
            flexDirection: 'column',
            alignSelf: 'center',
            marginTop: '20px',
            marginBottom: 2,
            width: 200,
          }}>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                sx={{
                  height: '200px',
                  width: '200px',
                  display: 'flex',
                }}
                image={wish?.image && !fileUrl ? `${process.env.REACT_APP_BACKEND_URL_PLAIN}${wish?.image}` : fileUrl}
                title="Contemplative Reptile"
              />
            </CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {text}
              </Typography>
            </CardContent>
          </Card>
        )}
        <Button
          type="submit"
          size="large"
          variant="contained"
          sx={{ marginBottom: 2}}
          disabled={!text}
        >
          { action === 'add' ? 'Add the Wish' : 'Change the Wish'}
        </Button>
      </Box>
    </Container>
  );
}
