import React, { useState } from 'react';
import {
  Box,
  Button,
  Stack,
} from '@mui/material';
import BookingCardButton from '../organisms/BookingCardButton';
import EmailConfirmationDialog from './EmailConfirmationDialog';
import CustomCardContent from '../organisms/CardContent';
import WishPopup from './WishPopup';

export default function WishGrid({ wishes }) {
  const [selectedWish, setSelectedWish] = useState();
  const [wishPopup, setWishPopup] = useState();

  return (
    <Stack
      marginTop={2}
      flexDirection={"row"}
      flexWrap={"wrap"}
      justifyContent={"space-around"}
      alignItems={"self-start"}
    >
      {wishes.map((wish) => (
        <Box
        key={wish?.id}
        sx={{
          position: 'relative',
        }}
        >
          <CustomCardContent wish={wish} />
          <Button
            onClick={() => setWishPopup(wish)}
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 24,
              marginBottom: 4,
              marginRight: 5,
            }}
          >
            Expand
          </Button>
          <Box
            sx={{
              marginLeft: 10,
              marginBottom: 4,
              marginRight: '31px',
              position: 'absolute', 
              bottom: 0, 
              right: 0, 
            }}
          >
            <BookingCardButton wish={wish} setSelectedWish={setSelectedWish}/>
          </Box>
        </Box>
      ))}
      <WishPopup wish={wishPopup} onClose={() => setWishPopup(undefined)}/>
      <EmailConfirmationDialog openForWish={selectedWish} onClose={() => setSelectedWish(undefined)} />
    </Stack>
  );
}