import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDeleteGift } from '../services/wishlist';
import { useSnackbar } from '../providers/SnackbarProvider';


export default function DeleteWishDialog({ open, setOpenDeleteDialog, wish }) {

  const { mutate: deleteGift } = useDeleteGift();
  const { openSnackbar } = useSnackbar();
  const theme = useTheme();

  const handleDelete = (wish) => {
    deleteGift(wish, {
      onSuccess() {
        openSnackbar({ message: 'You successfully change the wish', variant: 'success' });
      },
      onError() {
        openSnackbar({ message: 'An error occurred while changing this gift', variant: 'error' });
      },
      onSettled() {
        handleClose();
      },
    });
  };

  const handleClose = () => {
    
    setOpenDeleteDialog(false);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '20px',
          border: `1.5px solid ${theme.palette.primary.main}`,
          width: '500px',
        },
      
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',

        }}
      >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      >
        <CloseIcon />
      </IconButton>
        {'Are you sure you want to delete the wish?'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          If you delete this wish, you will not be able to restore it in the future.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Back</Button>
        <Button onClick={() => handleDelete(wish)}>I am Sure</Button>
      </DialogActions>
    </Dialog>
  );
}