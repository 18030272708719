import { useState } from 'react';
import axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { isValidPassword } from '../utils/utils';
import { Box } from '@mui/material';
import { useSnackbar } from '../providers/SnackbarProvider';
import { useSearchParams } from 'react-router-dom';
import PasswordInput from '../organisms/PasswordInput';

async function resetPassword(password, passwordConfirmation, code, openSnackbar) {

  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/reset-password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        password,
        passwordConfirmation,
        code,
      }),
    });

    const responseJSON = await response.json();
    const token = responseJSON?.jwt;
    localStorage.setItem('PGToken', 'Bearer ' + token);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    window.location.href = '/new-wish';
    openSnackbar({ message: 'You successfully reset the password', variant: 'success' });
    return response;
  } catch (error) {
    openSnackbar({ message: 'An error occurred while resetting the password', variant: 'error' });
  }
}

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const { openSnackbar } = useSnackbar();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await resetPassword(password, passwordConfirmation, code, openSnackbar);

    } catch(error) {
      throw new Error(error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <PasswordInput
            password={password}
            handlePassword={(e) => setPassword(e.target.value)}
          />
          <PasswordInput
            password={passwordConfirmation}
            handlePassword={(e) => setPasswordConfirmation(e.target.value)}
          />
          {
            !isValidPassword(password) && password.length >=9 ?
              <Typography
                variant="caption"
                color="error"
                display="block"
              >
                Password must contain at least one digit, one uppercase and one lowercase letter, and at least 9 or more characters
              </Typography>
              : ''
          }
          {
            ((passwordConfirmation.length >= password.length && password !== passwordConfirmation)) ?
              <Typography
                variant="caption"
                color="error"
                display="block"
              >
                Passwords do not match
              </Typography>
              : ''
          }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={
              (!password && !passwordConfirmation) ||
              password !== passwordConfirmation ||
              password.length < 9 || passwordConfirmation.length < 9}
            sx={{ mt: 3, mb: 2 }}
          >
            Reset Password
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/signin" variant="body2">
                I remembered my password
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}