import * as React from 'react';
import {
  Box,
  Typography,
  useTheme,
} from '@mui/material';
import no from '../img/no.png';
import infinity from '../img/infinity.png';
import target from '../img/target.png';

function StrengthQuoteNegative (){

  return (
    <Typography
    sx={{
      color: 'black',
      fontSize: '22px',
      fontWeight: '400',
      lineHeight: '1.5',
      marginTop: '10px',
      marginLeft: '20px',
    }}
  >
    <span style={{color: '#A91F5C'}}>Eliminate</span> Unwanted Gifts
  </Typography>
  );
}

function StrengthQuotePositive (){
  return (
    <Typography
    sx={{
      color: 'black',
      fontSize: '22px',
      fontWeight: '400',
      lineHeight: '1.5',
      marginTop: '10px',
      marginLeft: '20px',
    }}
  >
    Receive <span style={{color: '#A91F5C'}}>Thoughtful Gifts</span>
  </Typography>
  );
}

function StrengthQuoteShare (){
  return (
    <Typography
    sx={{
      color: 'black',
      fontSize: '22px',
      fontWeight: '400',
      lineHeight: '1.5',
      marginTop: '10px',
      marginLeft: '20px',
    }}
  >
    Easily <span style={{color: '#A91F5C'}}>Share</span> Your Wishlist
  </Typography>
  );
}

function NoImage() {
  const theme = useTheme();

  return (
    <Box
      component={"img"}
      sx={{
        maxWidth: '70px',
        height: '70px' ,
        [theme.breakpoints.up('md')]: {
          maxWidth: '58px',
          height: '58px'
        },
        objectFit: 'cover',
        marginLeft: '10px',
      }}
      src={no}
    />
  )
}

function InfinityImage() {
  const theme = useTheme();

  return (
    <Box
      component={"img"}
      sx={{
        maxWidth: '70px',
        height: '70px' ,
        [theme.breakpoints.up('md')]: {
          maxWidth: '90px',
          height: '90px'
        },
        objectFit: 'cover',
        marginLeft: '12px',
      }}
      src={infinity}
    />
  )
}

function TargetImage() {
  const theme = useTheme();
  return (
    <Box
      component={"img"}
      sx={{
        maxWidth: '70px',
        height: '70px' ,
        [theme.breakpoints.up('md')]: {
          maxWidth: '90px',
          height: '90px'
        },
        objectFit: 'cover',
        marginLeft: '6px',
      }}
      src={target}
    />
  )
}

export {
  StrengthQuoteNegative,
  StrengthQuotePositive,
  StrengthQuoteShare, 
  NoImage,
  InfinityImage,
  TargetImage
}