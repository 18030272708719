import { createContext, useContext, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

const SnackbarProviderContext = createContext({
  openSnackbar: ({ message, variant }) => {},
  closeSnackbar: () => {},
});

export function useSnackbar() {
  return useContext(SnackbarProviderContext);
}

export default function SnackbarProvider({ children }) {
  const [showSnackbarProps, setShowSnackbarProps] = useState();

  const closeSnackbar = () => setShowSnackbarProps(props => ({ ...props, open: false }));

  const providerValue = {
    openSnackbar: ({ message, variant }) => setShowSnackbarProps({ message, variant, open: true }),
    closeSnackbar,
  };

  return (
    <SnackbarProviderContext.Provider value={providerValue}>
      {children}
      <Snackbar
        open={showSnackbarProps?.open}
        autoHideDuration={2000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={closeSnackbar}
          severity={showSnackbarProps?.variant}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {showSnackbarProps?.message}   
        </Alert>
      </Snackbar>
    </SnackbarProviderContext.Provider>
  );
}