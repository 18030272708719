import React, { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  useTheme
} from '@mui/material';
import CustomCardContent from '../organisms/CardContent';
import { BinSvg, PencilSvg } from '../components/SvgIcons';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function WishPreviewCard({ wish,
  setSelectedWish,
  setOpenDeleteDialog,
  setWishForDelete,
  source,
  setWishPopup
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (wish) => {
    setWishForDelete(wish);
    setOpenDeleteDialog(true);
  }

  return (
    <Box
    sx={{
      width: 300,
      margin: 4,
      height: 300,
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        marginBottom: 6,
      },
    }}
    >
      <Box>
        <IconButton
          aria-label="close"
          onClick={handleClick}
          sx={{
            position: 'absolute',
            right: -10,
            top: 20,
            zIndex: 1,
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          sx={{ zIndex: 1000 }}
        >
          <MenuItem onClick={() => handleDelete(wish)}
            sx={{
              gap: 1,
            }}
          >
            <BinSvg />
            Delete
          </MenuItem>
          <MenuItem onClick={() => setSelectedWish(wish)}
            sx={{
              gap: 1,
            }}
          >
            <PencilSvg />
            Edit
          </MenuItem>
        </Menu>
      </Box>
      <CustomCardContent source={source}wish={wish} />
      <Button
          onClick={() => setWishPopup(wish)}
          sx={{
            position: 'absolute',
            bottom: -30,
            right: 0,
            marginBottom: 0,
            marginTop: 20,
            marginRight: 0,
          }}
        >
          Preview
      </Button>
    </Box>
    
  );
}

