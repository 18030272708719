import * as React from 'react';
import {
  Button,
  Typography,
  useTheme
} from '@mui/material';

export function LogOutButton() {
  const theme = useTheme();
  return (
    <Button
      variant="text"
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        borderRadius: '20px',
        width: '100px',
        height: '50px',
        margin: '10px',
        ":hover": {
          textDecoration: 'underline',
          backgroundColor: 'transparent',
        },
        [theme.breakpoints.down('sm')]: {
          width: '90px',
          height: '35px',
        }, 
      }}
      onClick={() => {
        localStorage.removeItem('PGToken');
        window.location.href = '/';
      }}
    >
      <Typography
        sx={{
          [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
          },
        }}
      >
        Sign Out
      </Typography>
  </Button>
  );
};

export function SignInButton({ handleClick }) {
  const theme = useTheme();
  return (
    <Button
      variant="text"
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.primary.main,
        borderRadius: '20px',
        width: '80px',
        height: '50px',
        margin: '10px',
        ":hover": {
          textDecoration: 'underline',
          backgroundColor: 'transparent',
        },
        [theme.breakpoints.down('sm')]: {
          width: '70px',
          height: '35px',
        }, 
      }}
      onClick={() => handleClick('sign-in')}
    >
      <Typography
        sx={{
          [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
          },
        }}
      >
        Sign In
      </Typography>
    </Button>
  );
};

export function SignUpButton ({handleClick}) {
  const theme = useTheme();
  return (
    <Button
    variant="contained"
    sx={{
      color: theme.palette.background.default,
      borderRadius: '20px',
      width: '100px',
      height: '35px',
      margin: '10px',
      ":hover": {
        color: theme.palette.background.default,
      },
      [theme.breakpoints.down('sm')]: {
        width: '95px',
        height: '35px',
      },  
    }}

    onClick={() => handleClick('sign-up')}
    >
    <Typography
      sx={{
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
      }}
    >
      Sign Up
    </Typography>
    </Button>
  );
};

export function HomeButton() {
  const theme = useTheme();
  return (
    <Button
      variant="contained"
      sx={{
        color: theme.palette.background.default,
        borderRadius: '20px',
        width: '100px',
        height: '35px',
        margin: '10px',
        ":hover": {
          color: theme.palette.background.default,
        },
        [theme.breakpoints.down('sm')]: {
          width: '95px',
          height: '35px',
        },  
      }}

      onClick={() => {
        window.location.href = '/new-wish';
      }}
    >
    <Typography
      sx={{
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
      }}
    >
      Home
    </Typography>
  </Button>
  );
}

export function CreateWishlistButton ({ handleClick }) {

  const onClickRedirect = () => {
    if (localStorage.getItem('PGToken')) {
      window.location.href = '/new-wish';
    } else {
      handleClick();
    }
  }
  const theme = useTheme();
  return (
    <Button 
    variant="contained"
    sx={{
      color: '#fff',
      alignSelf: 'center',
      borderRadius: '50px',
      width: '180px',
      height: '40px',
      fontSize: '15px',
      fontWeight: '700',
      marginBottom: '20px',
      ":hover": {
        color: '#fff',
      },
      [theme.breakpoints.up('md')]: {
        width: '230px',
        height: '70px',
        fontSize: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '50%',
        height: '50px',
      },
    }}
    onClick={onClickRedirect}
  >
    Create Wishlist
  </Button>
  );
}
