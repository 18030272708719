import React, { useState } from 'react';
import {
  Box,
  Stack,
} from '@mui/material';
import PostPreviewCard from '../organisms/PostPreviewCard';

export default function PostsGrid({ posts }) {

  return (
    <Stack

      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignItems: 'flex-start',
      }}
    >
      {posts.map((post) => (
        <Box
        key={post?.id}
        sx={{
          position: 'relative',
        }}
        >
          <PostPreviewCard post={post} />
        </Box>
      ))}
    </Stack>
  );
}