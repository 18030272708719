import React, { useState } from 'react';
import { Box, Container, Typography, useTheme } from '@mui/material';
import PostsGrid from '../components/PostsGrid';
import { useParams } from 'react-router-dom';
import { usePosts } from '../services/posts';
import background from '../img/background.png';
import Header from '../components/landing/Header';
import SignInSignUpDialog from '../components/SignInSignUpDialog';

export default function Posts() {
  const { uid } = useParams();
  const { data: posts, isPending, isError } = usePosts();
  const [isSignUpOpen, setIsSignUpOpen] = useState(false);
  const [action, setAction] = useState('sign-in');
  const theme = useTheme();

  if (isPending) return 'Loading...';
  if (isError) return 'An error has occurred: ' + isError.message;

  return (
    <Container component="main"
    maxWidth={false}
    disableGutters
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    }}
    >
      <Header setIsSignUpOpen={setIsSignUpOpen} setAction={setAction} source={'externalWishlist'}/>
      <Box
        sx={{
          backgroundColor: '#fcfaf7',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundImage: posts?.length === 0 ? `url(${background})` : '',
          backgroundSize: 'contain no-repeat',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minHeight: '100vh',
          width: '100%',
        }}
      >
        {posts?.length === 0 && (
          <Typography
            sx={{
              textAlign: 'center',
              width: '50%',
              marginTop: '20px',
              fontSize: '20px',
              marginBottom: '20px',
              color: theme.palette.primary.main,
              [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                width: '80%',
                marginTop: '90px',
              },

            }}
          >
            ✨There is no posts yet✨
          </Typography>
        
        )}
          <Box
            sx={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'start',
              alignItems: 'start'
            }}
          >
            <PostsGrid posts={posts} />
          </Box>
        <SignInSignUpDialog isOpen={isSignUpOpen} onClose={setIsSignUpOpen} action={action}/>
      </Box>
    </Container>
  );
}