import React, { useState } from 'react';
import {
  Button,
  Chip,
  Link,
  Tooltip,
} from '@mui/material';

export default function BookingCardButton({ wish, setSelectedWish, style }) {
  const [open, setOpen] = useState(false);

  if (wish.attributes.is_booked) {
    return (
      <Tooltip
        title="This wish has already been booked"
        arrow
      >
        <Chip
          label="Booked"
          style={style}
        />
      </Tooltip>
    );
  }

  if (wish.attributes.isMonetaryGift) {
    return (
      <Tooltip
        title="This is a monetary gift, you can contribute by clicking this button."
        arrow
      >
        <Button
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
          style={style}
        >
          <Link
            href={wish.attributes.link}
            target="_blank"
            rel="noopener noreferrer"
            color="inherit"
            sx={{
              color: 'white',
              textDecoration: 'none',
            }}
          >
            Contribute
          </Link>
        </Button>
      </Tooltip>
    );
  }

  if (wish.attributes.is_booked === false) {
    return (
      <>
      <Button
        variant="contained"
        onClick={() => {
          setSelectedWish(wish);
        }}
        style={style}
      >
        Book
      </Button>
      </>
    );
  }
}