import * as React from 'react';
import {
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import hands from '../../img/hands.png';
import socks from '../../img/socks.png';
import share from '../../img/share.png';
import StrengthCard from '../../organisms/StrengthCard';
import {
  StrengthQuoteNegative,
  StrengthQuotePositive,
  StrengthQuoteShare,
  NoImage,
  InfinityImage,
  TargetImage,
} from '../../molecules/landing';

export default function StrengthComponent() {

  const theme = useTheme();  

  return (
    <Stack
      sx={{
        flexDirection: 'column',
        height: 'auto',
        marginTop: '20px',
        alignSelf: 'center',
        backgroundColor: '#fff8e1',
        width: '100%',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
        marginBottom: '50px',
      }}
    >
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          width: '80%',
          [theme.breakpoints.down('sm')]: {
            width: '90%',
            display: 'flex',
            justifySelf: 'center',
            alignSelf: 'center',
          },
        }}
      >
        <Typography
          variant="h2"
          fontSize={24}
          fontWeight={700}
          marginTop="30px"
          marginBottom="25px"
          sx={{
            [theme.breakpoints.up('md')] : {
              alignSelf: 'center'
            },
          }}
        >
          How Wishful Touch Enhances Your Gifting Experience
        </Typography>
        <Stack
          alignItems={'center'}
          sx={{
            flexDirection: 'column',
            alignSelf: 'start',
            alignItems: 'center',
            justifyContent: 'space-between',
            [theme.breakpoints.up('md')]: {
              flexDirection: 'row',
              justifyContent: 'space-between',
            },
          }}
        >
          <StrengthCard
            decor={<NoImage/>}
            image={socks}
            color={'#F2F2F2'}
            title={<StrengthQuoteNegative/>}
            description={'No more unwanted presents cluttering your space.'}
          />
          <StrengthCard
            decor={<NoImage/>}
            image={hands}
            color={'#E8F0FE'}
            title={<StrengthQuotePositive/>}
            description={'No more unwanted presents cluttering your space.'}
          />
          <StrengthCard
            decor={<NoImage/>}
            image={share}
            color={'#FDEBD0'}
            title={<StrengthQuoteShare/>}
            description={'No more unwanted presents cluttering your space.'}
          />
        </Stack>
      </Stack>
    </Stack>
  )

}