import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Link,
  useTheme
} from '@mui/material';
import placeholder from '../placeholder.png';

export default function PostPreviewCard({ post }) {

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        width: '100%',
        padding: '20px',
        borderRadius: '5px',
      }}
    >
      <Box
        component={'img'}
        src={  
          post?.attributes?.cover.data ?
          `${process.env.REACT_APP_BACKEND_URL_PLAIN}${post.attributes.cover.data.attributes.url}` : placeholder
          }
        sx={{
        width: 500,
        height: '330px',
        position: 'relative',
        borderRadius: '5px',
        objectFit: 'cover',
        [theme.breakpoints.down('md')]: {
          width: '90%',
        }
        }}
      />
      <Typography
        color={'#434B4F'}
        sx={{
          fontSize: '25px',
          fontWeight: 700,
          marginTop: '14px',
          [theme.breakpoints.down('md')]: {
            width: '85%',
          }
        }}
      >
       <a
        href='/best-gift-for-a-software-engineer'
        style={{textDecoration: 'none'}}
       >{post.attributes.title}</a>
      </Typography>
      <Typography
      color={'#686E72'}
        sx={{
          fontSize: '20px',
          fontWeight: 500,
          width: '500px',
          [theme.breakpoints.down('md')]: {
            width: '85%',
          }
        }}
      >
        {post.attributes.description}
      </Typography>
    </Box>
  )
}

