import {
  Dialog,
  IconButton,
  useTheme
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import WishPopupContent from "./WishPopupContent";

export default function WishPopup({ wish, onClose, source }) {
  const theme = useTheme();

  if (!wish) return null;

  return (
    <Dialog
      open={!!wish}
      onClose={onClose}

      PaperProps={{
        sx: {
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: '20px',
          border: `1.5px solid ${theme.palette.primary.main}`,
          width: '500px',
          [theme.breakpoints.down('sm')]: {
            width: '350px',
          },
        },
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <WishPopupContent wish={wish} source={source}/>
    </Dialog>
  );
}