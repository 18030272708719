import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Loader() {
  return (
    <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        justifySelf: 'center',
        marginTop:'25%'
    }}>
      <CircularProgress />
    </Box>
  );
}

export { Loader };