import { Dialog, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import EmailConfirmationForm from './EmailConfirmationForm';

export default function EmailConfirmationDialog({ openForWish, onClose }) {
  return (
    <Dialog
      open={!!openForWish}
      onClose={onClose}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <EmailConfirmationForm
        wish={openForWish}
        onDone={onClose}
      />
    </Dialog>
  );
}