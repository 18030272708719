import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { deleteWish, fetchWishlist, bookGift, changeWish, createWishListUrl } from '../utils/utils';

const WISHLIST_QUERY_KEY_PREFIX = 'wishlist';
const WISHLIST_QUERY_KEY = wishlistUid => [WISHLIST_QUERY_KEY_PREFIX, wishlistUid];
const WISHLIST_URL_QUERY_KEY = ['wishlistUrl'];

const WISHES_QUERY_KEY = 'wishes'; 


export function isLoggedIn() {
  return !!localStorage.getItem('PGToken');
}


export function useWishes(wishlistUid) {
  return useQuery({
    queryKey: WISHLIST_QUERY_KEY(wishlistUid),
    queryFn: () => fetchWishlist(wishlistUid),
  });
}

export function useBookGift() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ wishId, email }) => bookGift(wishId, email),
    onSuccess() {
      queryClient.invalidateQueries([WISHLIST_QUERY_KEY_PREFIX]);
    },
  });
}

export function useEditGift() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ wishId, wish }) => changeWish(wishId, wish),
    onSuccess() {
      queryClient.invalidateQueries([WISHES_QUERY_KEY]);
    },
  });
}

export function useWishlistLink() {
  return  useQuery({
    queryKey: WISHLIST_URL_QUERY_KEY,
    queryFn: () => createWishListUrl(),
  });
}

export function useDeleteGift() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: wish => deleteWish(wish),
    onSuccess() {
      queryClient.invalidateQueries([WISHES_QUERY_KEY]);
    },
  });
}