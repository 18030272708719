import { Dialog, IconButton, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SignInSignUpForm from './SignInSignUpForm';


export default function SignInSignUpDialog({ isOpen, onClose, action }) {
  const theme = useTheme();

  const handleClose = (event, reason) => {
    event.preventDefault();
    onClose(false);
  };

  return (
    <Dialog
    open={isOpen}
    onClose={handleClose}
    PaperProps={{
      sx: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px',
        border: `1.5px solid ${theme.palette.primary.main}`,
        width: '400px',
        [theme.breakpoints.down('sm')]: {
          width: '350px',
        },
        height: '500px',
      },
    }}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
    }}
    >
    <IconButton
      aria-label="close"
      onClick={() => onClose(false)}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
      }}
    >
      <CloseIcon />
    </IconButton>
    <SignInSignUpForm
      onClose={onClose}
      action={action}
    />
    </Dialog>
  );
}



