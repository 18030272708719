import * as React from 'react';
import { Box, Typography, useTheme } from '@mui/material';


export default function StrengthCard({ decor, image, title, color }) {

  const theme = useTheme(); 

  return (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      justifySelf: 'center',
      position: 'relative',
      width: '300px',
      height: '250px',
      [theme.breakpoints.up('sm')]: {
        width: '70%',
        marginBottom: '50px',
      },
      [theme.breakpoints.up('md')]: {
        width: '30%',
        height: '250px',
        marginRight: '20px',
      },
      [theme.breakpoints.down('sm')]: {
        marginBottom: '20px',
        width: '100%',
      },
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: color,
        width: '100%',
        marginTop: '0px',
        borderRadius: '20px',
      }}
    >
      {title}
      <Box
        component={'img'}
        src={image}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '40%',
          marginTop: '20px',
          marginBottom: '20px',
        }}
      />
    </Box>
  </Box>
  )
}