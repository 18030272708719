import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import background from '../img/background.png';
import WishForm from '../components/WishForm';
import { Wish } from '../components/Wish';
import { fetchWishes, addNewWish, createWishListUrl } from '../utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from '../providers/SnackbarProvider';
import DeleteWishDialog from '../components/WishDeleteDialog';
import Header from '../components/landing/Header';

function Wishes() {
  const theme = useTheme();

  const queryClient = useQueryClient();
  const { isPending, isError, data, error } = useQuery({
    queryKey: ['wishes'],
    queryFn: fetchWishes,
  });
  const { data: wishListUrl } = useQuery({
    queryKey: ['wishlistUrl', !!data?.length],
    queryFn: createWishListUrl,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [wish, setWish] = useState();
  const { openSnackbar } = useSnackbar();

  const mutation = useMutation({
    mutationFn: addNewWish,
    onSuccess: () => {
      queryClient.invalidateQueries('wishes');
    },
  });

  if (isPending) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  const handleClick = async () => {
    navigator.clipboard.writeText(wishListUrl);
    openSnackbar({
      message: 'The link of your wishlist has been copied to your clipboard',
      variant: 'success',
    });
  };

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }
  
  return (
    <Container
      component="main"
      maxWidth={false}
      disableGutters
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <Header source={'internalWishlist'}/>
        <Box
          sx={{
            backgroundColor: '#fcfaf7',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundImage: data?.length === 0 ? `url(${background})` : '',
            backgroundSize: 'contain no-repeat',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            minHeight: '100vh',
            width: '100%',
          }}
        >
        <Typography
          sx={{
            textAlign: 'center',
            width: '50%',
            marginTop: '20px',
            fontSize: '20px',
            marginBottom: '20px',
            color: theme.palette.primary.main,
            [theme.breakpoints.down('sm')]: {
              fontSize: '16px',
              width: '80%',
              marginTop: '90px',
            },

          }}
        >
          Welcome to your wishlist✨
          <br/>
          Add your wishes and share them with your friends.
        </Typography>
        <Box
          sx={{
            marginTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx = {{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
          {data?.length > 0 && (
            <Box>
              <Button
                variant="contained"
                onClick={handleClick}
                sx={{ marginRight: 2 }}
              >
                  Share Wishlist
              </Button>
            </Box>)}
            <Button
              variant="contained"
              onClick={handleClickOpenDialog}
            >
              Add new wish
            </Button>
          </Box>
          <Wish wishes={data} setOpenDeleteDialog={setOpenDeleteDialog} setWishForDelete={setWish}/>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}  
            PaperProps={{
              sx: {
                paddingTop: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '20px',
                border: `1.5px solid ${theme.palette.primary.main}`,
                width: '500px',
                [theme.breakpoints.down('sm')]: {
                  width: '350px',
                },
              },
            }}
          >
            <DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleCloseDialog}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <WishForm
              onSubmit={(...args) => {
                mutation.mutate(...args);
                handleCloseDialog();
              }}
              action={'add'}
            />
          </Dialog>
          <DeleteWishDialog
            open={openDeleteDialog}
            setOpenDeleteDialog={setOpenDeleteDialog}
            wish={wish}
          />
        </Box>
      </Box>
    </Container>
  );
}

export { Wishes };
