import React, { useState } from 'react';
import {
  Box,
} from '@mui/material';
import WishDialog from './WishDialog';
import WishPreviewCard from './WishPreviewCard';
import WishPopup from './WishPopup';

function Wish({ wishes, setOpenDeleteDialog, setWishForDelete }) {

  const [selectedWish, setSelectedWish] = useState();
  const [wishPopup, setWishPopup] = useState();

  return (
    <Box
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'self-start',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        marginRight: 4,
      }}
    >
      {wishes.map((wish) => (
        <WishPreviewCard
          key={wish?.id}
          wish={wish}
          setSelectedWish={setSelectedWish}
          setOpenDeleteDialog={setOpenDeleteDialog}
          setWishForDelete={setWishForDelete}
          setWishPopup={setWishPopup}
          source='internal-wishlist'
        />
      ))}
      <WishDialog openForWish={selectedWish} onClose={() => setSelectedWish(undefined)}/>
      <WishPopup wish={wishPopup} onClose={() => setWishPopup(undefined)} source={'internalWishlist'}/>
    </Box>
  );
}

export { Wish };
