import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { uid } from 'uid/secure';


export async function signUp(credentials) {
  const userId = uid(16);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/auth/local/register`,
      {
        username: credentials?.email,
        email: credentials?.email,
        password: credentials?.password,
        uid: userId,
      },
    );

    const token = response.data?.jwt;
    localStorage.setItem('PGToken', 'Bearer ' + token);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    window.location.href = '/new-wish';
  } catch (error) {
    console.log(error);
  }
}


export async function signIn(credentials) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/local`, {
      identifier: credentials?.email,
      password: credentials?.password,
    });

    const token = response.data?.jwt;
    localStorage.setItem('PGToken', 'Bearer ' + token);
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    window.location.href = '/new-wish';
    return response;
  } catch (error) {
    return error;
  }
}

export const uploadImage = async (image) => {
  try {
    const formData = new FormData();
    formData.append('files', image);

    const uploadResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/upload`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('PGToken'),
      },
      body: formData,
    });
    const uploadResponseJson = await uploadResponse.json();
    return uploadResponseJson[0]?.id;
  } catch (error) {
    console.error('Error uploading image:', error);
  }
};

export async function destroyImage({ imageId, token }) {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/files/${imageId}`, {
      method: 'DELETE',
      headers: {
        Authorization: token,
      },
    });
    return response;
  } catch (error) {
    console.error('Error:', error);
  }
}

export async function addNewWish(wish, setFileUrl, setText, setLink, setFile) {
  let wishlistId;
  const decoded = jwtDecode(localStorage.getItem('PGToken'));
  const userId = decoded.id;
  let uploadedImageId;
  
  if (wish.image) {
    uploadedImageId = await uploadImage(wish.image);
  }

  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/wishlists?filters[author][$eq]=${userId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('PGToken'),
        },
      },
    );

    const userWishlist = await resp.json();
    wishlistId = userWishlist?.data[0]?.id;

    if (userWishlist.data.length === 0) {
      const wishlistt = await fetch(`${process.env.REACT_APP_BACKEND_URL}/wishlists`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('PGToken'),
        },
        body: JSON.stringify({ data: { uid: uid(), author: userId } }),
      });

      const wishlist = await wishlistt.json();
      wishlistId = wishlist.data.id;
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/gift-ideas`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('PGToken'),
      },
      body: JSON.stringify({
        data: {
          name: wish.name,
          link: wish.link,
          image: uploadedImageId,
          comment: wish.comment,
          user: { connect: [{ id: userId }] },
          wishlist: { connect: [{ id: wishlistId }] },
          price: Number(wish.price),
          isMonetaryGift: wish.isMonetaryGift,
        },
      }),
    });

    setFile(null);
    return response.json();
  } catch (error) {
    console.error('Error:', error);
  }
}

export async function fetchWishes() {
  const token = jwtDecode(localStorage.getItem('PGToken')); //data is what you sent in.

  if (!token) {
    return;
  }

  const userId = token.id;

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/gift-ideas?filters[user][$eq]=${userId}&populate=image`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('PGToken').toString(),
        },
      },
    );
    const giftIdeas = await response.json();

    if (!giftIdeas?.data) {
      return;
    }
    return giftIdeas.data;
  } catch (error) {
    console.error('Error1:', error);
  }
}


export async function createWishListUrl() {
    const decoded = jwtDecode(localStorage.getItem('PGToken')); //data is what you sent in.
    const userId = decoded.id;

    const resp = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/wishlists?filters[author][$eq]=${userId}`,
        {
          method: 'GET',
          headers: {
            Authorization: localStorage?.getItem('PGToken'),
          },
        },
      );
  
    const userWishlist = await resp.json();
    if (!userWishlist.data) {
      return ''
    }
    const wishlistUid = userWishlist?.data[0]?.attributes?.uid;

    if (wishlistUid === undefined) {
      throw new Error("wishlist doesn't exist");
    }

    return `${window.location.origin}/wishlist/${wishlistUid}`
}

export async function fetchWishlist(uid) {

    const resp = await fetch(`${process.env.REACT_APP_BACKEND_URL}/wishlists?filters[uid][$eq]=${uid}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
    })

    const wishlistJSON = await resp.json();
    const wishlistId = wishlistJSON.data[0]?.id;

    if (wishlistJSON.data.length > 0) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/gift-ideas?filters[wishlist][$eq]=${wishlistId}&populate=image`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: localStorage.getItem('PGToken'),
            },
          },
        );
        const giftIdeas = await response.json();
    
        if (!giftIdeas?.data) {
          return;
        }
        return giftIdeas.data;
      } catch (error) {
        console.error('Error:', error);
      }
      
    }
  }

  export const isValidEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  export const isValidPassword = (password) => {
    return password.match(/[0-9]/) && password.match(/[a-z]/) && password.match(/[A-Z]/);
  }

  export async function bookGift(giftId, email) {
    try {
      const resp = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/book-gift/${giftId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: {
              bookedBy: email.toString() ,
              is_booked: true,
            },
          }),
        },
      );
      return resp;
    } catch (error) {
      console.error('Error:', error);
    }
  }

  export const fetchUser = async () => {
    const token = localStorage.getItem('PGToken');
    
    if (!token) {
      window.location.href = '/';
      throw new Error('No token found');
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/me`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
    });

    if (response.status === 401) {
      localStorage.removeItem('PGToken');
      throw new Error('Unauthorized');
    }
    return response.json();
  };

export async function changeWish(wishId, wish) {

  let uploadedImageId;

  if (wish.image) {
    uploadedImageId = await uploadImage(wish.image);
  }

  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/gift-ideas/${wishId}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            name: wish.name,
            link: wish.link,
            image: uploadedImageId,
            comment: wish.comment,
            price: Number(wish.price),
            isMonetaryGift: wish.isMonetaryGift,
          },
        }),
      },
    );
    return resp;
  } catch (error) {
    console.error('Error:', error);
  }
}

export async function deleteWish(wish) {
  const token = localStorage.getItem('PGToken');
  if (!token) {
    throw new Error('No token found');
  }
  
  if (wish?.attributes?.image?.data?.id) {
    await destroyImage({imageId: wish.attributes.image?.data.id, token});
  }

  try {
    const resp = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/gift-ideas/${wish.id}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      },
    );
    return resp;
  } catch (error) {
    console.error('Error:', error);
  }
}

export async function fetchPosts() {

  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/posts?populate=cover`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    const posts = await response.json();

    if (!posts?.data) {
      return;
    }
    return posts.data;
  } catch (error) {
    console.error('Error:', error);
  }
}