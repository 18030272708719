import * as React from 'react';
import {
  Box,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import checkBox from '../../img/checkbox.png';
import friends from '../../img/exchange.png';
import { CreateWishlistButton } from '../../molecules/buttons';

function Pros ({ actions }) {

  const theme = useTheme();
  return actions.map((action, index) => {
    return (
      <Stack
        key={index}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          height: 'auto',
          justifyContent: 'flex-start',
          marginBottom: '40px'
        }}
      >
        <Box
          component="img"
          src={checkBox}
          sx={{
            width: '40px',
            height: '40px',
            alignSelf: 'center',
            marginRight: '20px'
          }}
        />
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: '100%',
            height: 'auto',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              width: '90%',
              display: 'flex',
              lineBreak: 'auto',
              lineHeight: '26px',
              alignSelf: 'center',
              fontSize: '20px',
              marginBottom: '5px',
              color: theme.palette.primary.main,
              [theme.breakpoints.up('md')]: {
                fontSize: '24px',
                width: '80%',
              },
            }}
          >
            {action.split('.')[0]} <br />
          </Typography>
          <Typography
              sx={{
                width: '90%',
                display: 'flex',
                lineBreak: 'auto',
                lineHeight: '26px',
                alignSelf: 'center',
                fontSize: '20px',
                color: '#3a3232',
                [theme.breakpoints.up('md')]: {
                  fontSize: '24px',
                  width: '80%',
                },
              }}
          >
            {action.split('.').slice(1).join('.').trim()}
          </Typography>
        </Stack>
      </Stack>
    );
  })
}


export default function WhyChooseUs({ setIsSignUpOpen, setAction }) {

  const handleClick = (action) => {
    setIsSignUpOpen(true);
    setAction(action);
  }


  const reasons = [
    'No intrusive suggestions from other platforms. Craft a wishlist that includes only the gifts and experiences you genuinely desire',
    'Effortless Sharing. Easily share your wishlist with friends and family through a simple link, ensuring they know exactly what you want.',
    'Support Your Dreams. Add a link to your piggy bank as a wish so everyone can contribute to your dreams and help you achieve your goals.'
  ];

  const theme = useTheme();

   return (
    <Stack
      maxWidth={false}
      sx={{
        maxWidth: 'xl',
        alignSelf: 'center',
        alignContent: 'center',
        flexWrap: 'nowrap',
        height: 'auto',
        alignItems: 'revert',
        marginBottom: '20px',
        [theme.breakpoints.up('md')]: {
          justifyContent: 'center',
          flexDirection: 'column',
          justifySelf: 'center',
        },
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: '#3a3232',
          display: 'flex',
          lineBreak: 'auto',
          lineHeight: '26px',
          alignSelf: 'center',
          fontSize: '30px',
          marginBottom: '50px',
          [theme.breakpoints.up('md')]: {
            justifySelf: 'center',
            fontSize: '40px',
          },
        }}
      >
        Why Choose Us
      </Typography>
      <Stack
        sx={{
          flexDirection: 'column',
          [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
            flexDirection: 'row',
            justifySelf: 'center'
          },
        }}
      >
        <Stack
          sx={{
            alignItems: 'center',
            alignSelf: 'center',
            width: '80%',
            height: 'auto',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
              marginLeft: '100px',
              width: '50%',
            },
          }}
        >
          <Pros actions={reasons} />
        </Stack>
        <Box
          component="img"
          src={friends}
          sx={{
            width: '30%',
            height: '30%',
            height: 'auto',
            alignSelf: 'center',
            width: '60%',
            marginBottom: '20px',
            [theme.breakpoints.up('md')]: {
              width: '20%',
              height: '30%',
              marginLeft: '0px',
            },
          }}
        />
      </Stack>
      <CreateWishlistButton handleClick={() => handleClick('sign-up')} />
    </Stack>
  );
}