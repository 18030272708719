import { useState } from 'react';
import {
    Box,
    Button,
    Stack,
    TextField,
    Typography,
    LinearProgress,
} from '@mui/material';
import Container from '@mui/material/Container';
import { isValidEmail } from '../utils/utils';
import { useBookGift } from '../services/wishlist';
import { useSnackbar } from '../providers/SnackbarProvider';

export default function EmailConfirmationForm({ wish, onDone }) {
  const [email, setEmail] = useState('');
  const { mutate: bookGift, isPending } = useBookGift();
  const { openSnackbar } = useSnackbar();

  if (isPending) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '150px',
        }}
      >
        <Typography
          sx={{
            marginBottom: 2,
            width: '80%',
          }}
        >
          You will receive booking confirmation on your email
        </Typography>
        <LinearProgress 
          sx={{
            width: '80%',
          }}
        />
      </Box>
    )
  }
  
  const handleSubmit = event => {
    event.preventDefault();
    if (!isValidEmail(email)) {
      return;
    }

    bookGift({wishId: wish.id, email: email}, {
      onSuccess() {
        openSnackbar({ message: 'You successfully booked this gift', variant: 'success' });
      },
      onError() {
        openSnackbar({ message: 'An error occurred while booking this gift', variant: 'error' });
      },
      onSettled() {
        onDone();
      },
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box component="form" noValidate onSubmit={handleSubmit}>
      <Stack marginTop={8}>
        <Typography component="h2" variant="h5">
          Leave your email to make this wish come true    
        </Typography>
          <TextField
              required
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              sx={{
              margin: 2,
              width: '90%',
              }}
          />
        <Button
          type="submit"
          size="medium"
          variant="contained"
          sx={{
            marginBottom: 2,
            display: 'flex',
            alignSelf: 'center', 
            width: '60%',
          }}
          disabled={!email || !isValidEmail(email) || isPending}
        >
          Book
        </Button>
      </Stack>
      </Box>
    </Container>
  );
}