import { Dialog, DialogTitle, IconButton, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import WishForm from './WishForm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { changeWish } from '../utils/utils'

export default function WishDialog({ openForWish, onClose }) {
  const image = openForWish?.attributes?.image ? openForWish?.attributes?.image?.data?.attributes?.url : '';
  const queryClient = useQueryClient();
  const theme = useTheme();

  const mutation = useMutation({
    mutationFn: changeWish,
    onSuccess: () => {
      queryClient.invalidateQueries('wishes');
    },
  });
  return (
    <Dialog
    open={!!openForWish}
    onClose={onClose}
    PaperProps={{
      sx: {
        paddingTop: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '20px',
        border: `1.5px solid ${theme.palette.primary.main}`,
        width: '500px',
        [theme.breakpoints.down('sm')]: {
          width: '350px',
        },
      },
    }}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
    }}
    >
      <DialogTitle>
        <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      </DialogTitle>
    <WishForm
      wish={{
        comment: openForWish?.attributes?.comment,
        name: openForWish?.attributes?.name,
        link: openForWish?.attributes?.link,
        price: openForWish?.attributes?.price,
        isMonetaryGift: openForWish?.attributes?.isMonetaryGift,
        image: image,
        id: openForWish?.id,
      }}
      onSubmit={(...args) => {
        mutation.mutate(...args);
      }}
      onClose={onClose}
      action={'edit'}
    />
    </Dialog>
  );
}



